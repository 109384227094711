import {
  ErrorScreen,
  Login,
  ModalProvider,
  NextLoadingTransition,
  PersistLogin,
  RequireAuth,
  TopBar,
  Unauthorized,
} from '@assemblio/frontend/components';
import { usePreventZoom } from '@assemblio/frontend/hooks';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enableMapSet } from 'immer';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Assembler } from './pages/Assembler';
import classes from './Viewer.module.scss';
import { ThemeVariablesResolver } from '../theme/CSSVariables';
import { ViewerTheme } from '../theme/Viewer.theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  AssemblyPlans,
  Explorer as ExplorerV2,
  FavoriteProducts,
  Product,
  Project,
  RecentProducts,
} from '@assemblio/frontend/explorer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
enableMapSet();

function ViewerWithProvider() {
  usePreventZoom();

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={['editor', 'viewer', 'manager']} />}>
          <Route
            element={
              <ModalProvider>
                <div id="container" className={classes.container}>
                  <Outlet />
                </div>
              </ModalProvider>
            }
          >
            <Route path="/explorer" element={<ExplorerV2 />}>
              <Route index element={<AssemblyPlans />} />
              <Route path=":folderId" element={<AssemblyPlans />} />
              <Route path="project/:projectId" element={<Project />} />
              <Route path="product/:productId" element={<Product />} />
              <Route path="favorites" element={<FavoriteProducts />} />
              <Route path="recent" element={<RecentProducts />} />
            </Route>
            <Route
              path="/assembler/:instructionId"
              element={
                <div className={classes.disassembler_wrapper}>
                  <div className={classes.disassembler_header}>
                    <TopBar />
                  </div>
                  <div className={classes.disassembler_content}>
                    <Outlet />
                  </div>
                </div>
              }
            >
              <Route index element={<NextLoadingTransition component={<Assembler />} />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to="/explorer" />} />
    </SentryRoutes>
  );
}

export function Viewer() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={ViewerTheme} cssVariablesResolver={ThemeVariablesResolver} defaultColorScheme={'dark'}>
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
          <Notifications />
          <Router>
            <ReactQueryDevtools initialIsOpen={false} />
            <ViewerWithProvider />
          </Router>
        </Sentry.ErrorBoundary>
      </MantineProvider>
    </QueryClientProvider>
  );
}
